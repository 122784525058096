import { inject, Injectable } from "@angular/core";
import {
  Auth,
  authState,
  idToken,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  UserCredential,
  signOut,
  getAuth,
  User,
  updateEmail,
} from "@angular/fire/auth";

import { Observable, scheduled, asyncScheduler, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class AuthService {
  private _auth = inject(Auth);

  authState$ = authState(this._auth);
  idToken$ = idToken(this._auth);

  //   byGoogle(): Promise<UserCredential> {
  //     return signInWithPopup(this._auth, new GoogleAuthProvider()).then(
  //       (auth) => this._setUserData(auth)
  //     );
  //   }

  signup(email: string, password: string): Observable<UserCredential> {
    return scheduled(
      createUserWithEmailAndPassword(this._auth, email.trim(), password.trim()),
      asyncScheduler,
    );
  }

  login(email: string, password: string): Observable<UserCredential> {
    return scheduled(
      signInWithEmailAndPassword(this._auth, email.trim(), password.trim()),
      asyncScheduler,
    );
  }

  getUser(): Observable<User | null> {
    return scheduled(of(getAuth().currentUser), asyncScheduler);
  }

  updateEmail(user: User, newEmail: string): Observable<void> {
    // NOTE: In order for the email to be updated, the Email enumeration protection must be disabled in the Firebase console.
    return scheduled(updateEmail(user, newEmail), asyncScheduler);
  }

  logout(): Observable<void> {
    return scheduled(signOut(this._auth), asyncScheduler);
  }
}
